
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomActionSelector',
  props: {
    className: {
      type: String,
      default: 'text-center',
      required: false,
    },
  },
});
