import initialize, { mapGeoCodeModelToSearchHistoryModel, postSearchHistory } from '@twogo/geolocation';
import GeoCodeModel from '@twogo/geolocation/src/lib/models/location/GeoCodeModel';

const accessToken = window.localStorage.getItem('access_token') || 'dummy-token';
if (process.env.VUE_APP_GEOSERVICE_API_KEY && process.env.VUE_APP_GEOSERVICE_BASE_URL && accessToken) {
  initialize(process.env.VUE_APP_GEOSERVICE_API_KEY, process.env.VUE_APP_GEOSERVICE_BASE_URL, accessToken);
} else {
  console.info('Error: VUE_APP_GEOSERVICE_API_KEY and/or VUE_APP_GEOSERVICE_BASE_URL might be missing!');
}

export * from '@twogo/geolocation';

export const addToSearchHistory = (userId: string, locations: GeoCodeModel[]) => {
  if (!locations.length) return;

  const postData = locations.map((location) => mapGeoCodeModelToSearchHistoryModel(location));
  postSearchHistory(userId, postData);
};
