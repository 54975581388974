
import moment from 'moment';
import Vue, { PropType } from 'vue';
import locationPin from '@/assets/images/icons/icon-pin-start.svg';
import locationFlag from '@/assets/images/icons/icon-pin-flag.svg';
import { createMarkerBase, getStaticMapRouteUrl } from '@twogo/here-maps/src/lib/HereMapsService';
import CoordinatesModel from '@twogo/geolocation/src/lib/models/CoordinatesModel';
import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import { geoCode } from '@/services/geolocation/geolocationService';

type Site = {
  name: string;
  address: string;
};

export default Vue.extend({
  name: 'MoleculeTimetableCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: Object as PropType<moment.Moment>,
      required: true,
    },
    from: {
      type: Object as () => Site,
      required: true,
    },
    to: {
      type: Object as () => Site,
      required: true,
    },
    seats: {
      type: Number,
      required: false,
    },
    showSeats: {
      type: Boolean,
      default: false,
    },
    isLookingForDriver: {
      type: Boolean,
      default: false,
    },
    hasMap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      routes: [] as RouteModel[],
      startCoordinates: {} as CoordinatesModel,
      destCoordinates: {} as CoordinatesModel,
    };
  },
  components: {
    AtomSvgIcon,
    AtomText,
  },
  methods: {
    async getCoordinates(locationName: string): Promise<CoordinatesModel> {
      const { data } = await geoCode({ query: locationName });
      if (data.length) {
        return data[0].position;
      }
      return { lat: 0, lng: 0 };
    },
  },
  computed: {
    time(): string {
      return moment(this.date).format('HH:mm');
    },
    destCols(): number {
      const initialCols = 3;
      return this.hasMap ? initialCols : initialCols + 2;
    },
    getMarkers(): BaseMarkerModel[] {
      const markers = [] as BaseMarkerModel[];

      markers.push(createMarkerBase(locationPin, this.startCoordinates));
      markers.push(createMarkerBase(locationFlag, this.destCoordinates));

      return markers;
    },
    staticMapWithRoute(): string {
      const queryParams = {
        wayPoints: [this.startCoordinates, this.destCoordinates],
        imageSize: { w: 153, h: 96 },
        lineWeight: 4,
        lineColor: '009eaa',
      };

      return getStaticMapRouteUrl(queryParams);
    },
  },
  async mounted() {
    const {
      from: { address: from },
      to: { address: to },
    } = this;
    if (from && to && this.hasMap) {
      const [startCoordinates, destCoordinates] = await Promise.all([
        this.getCoordinates(from),
        this.getCoordinates(to),
      ]);
      this.startCoordinates = startCoordinates;
      this.destCoordinates = destCoordinates;
    }
  },
});
